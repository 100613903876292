import React from 'react'
import Home from './Pages/Home'

import {BrowserRouter,Route,Routes} from 'react-router-dom'
import Login from './Pages/Login';
import SdpRedirect from './Pages/SdpRedirect';
import Terms from './Pages/Terms';


function App() {
  return (
    <>
<BrowserRouter>

<Routes>

  <Route path='/' element={<Home/>}/>
  <Route path='/login' element={<Login/>}/>
  <Route path={'/doiRedirect.jsp'} element={<SdpRedirect/>}/>
  <Route path='/terms' element={<Terms/>}/>
</Routes>
</BrowserRouter>

    </>
  );
}

export default App;
