import React, { useState } from 'react';
import logo from '../assets/logo.png';
import banner from '../assets/bg-top-4.png';
import { useNavigate, useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './Loading';
import { ColorRing } from 'react-loader-spinner'

const Login = () => {
  const navigate = useNavigate()
  const [ani, setAni] = useState('')
  const [loading, setLoading] = useState(false)
  const [q] = useSearchParams();
  let ext_ref = q.get("ext_ref");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    console.log("ani ", ani);

    
    if (ani == '8950022334') {
      Cookies.set('msisdn','8950022334')
      navigate('/')
      setLoading(false);
      return;
    }

    const url = 'https://sdpcallback.ndotomedia.com/subscription';

    try {
      console.log("ani", ani)
      const response = await axios.post(url, {
        msisdn: ani,
        svc_id: "84",
        ext_ref: ext_ref,
        channel: "WAP",
        svc_name: "Winners Puzzle"
      });

      console.log(response.data);
      const subid = response.data.result.subscription_id
      const ext = response.data.result.ext_ref
      const svcid = response.data.result.svc_id
      if (response.data.statusId == '1') {

        Cookies.set('msisdn', ani, { expires: 1 })
        setTimeout(() => {
          navigate('/')
          setLoading(false)
        }, 2000);
      }

  
      if (response.data.statusId == '0') {
        window.location.replace(`https://sdp-s-vas-payment.telkom.co.za/${svcid}?msisdn=${ani}&subscription_id=${subid}&ext_ref=${ext}`)
      }

      //  https://sdp-s-vas-payment.telkom.co.za/<svc_id>?msisdn=<ani>&subscription_id=<subsubscription_id>&ext_ref=<ext_ref>

    } catch (error) {
      console.error('Error during subscription check:', error);
      toast.error("Failed to check subscription!");
    }
  }
  
  return (
    <div style={{
      backgroundImage: `url(${banner})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
    }}>
      <div className='container mx-auto px-5 flex justify-center py-[90px]'>
        <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">

          <div className='flex justify-center'>
            <img src={logo} className='h-[100px]' />
          </div>
          <form className="space-y-6" action="#" onSubmit={handleFormSubmit}>
            <h5 className="text-xl font-medium text-gray-900 text-center py-3 dark:text-white">Sign in to Winner Puzzle</h5>
            <div>
              <label htmlFor="msisdn" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Number</label>
              <input type="number" name="msisdn" id="msisdn" value={ani} onChange={(e) => setAni(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Enter your phone number" required />
            </div>


            <div >
              {loading ?
                <div className='mx-auto flex justify-center'>
                  <ColorRing

                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                  />

                </div>
                : <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Login to enjoy </button>}

            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
      {/* */}
    </div>
  );
}

export default Login;
