

import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie'

const SdpRedirect = () => {
    const [loading, setLoading] = useState(true);
    const [q] = useSearchParams();
    const navigate = useNavigate();
   
    const svc_id=84
    let msisdn = q.get("msisdn");
    // let svc_id=q.get("svc_id")
  let ext_ref = q.get("ext_ref");

    let subscription_id = q.get("subscription_id");

    console.log("redirect data", msisdn, subscription_id);


    axios.get(`https://sdpcallback.ndotomedia.com/check-sub?msisdn=${msisdn}&subscription_id=${subscription_id}`)
        .then(resp => {
            const data = resp.data;
            console.log("check-data =>", data);
            setLoading(false);

            if (data.statusId =='1') { 
                Cookies.set('msisdn',msisdn)
                navigate('/')
            } 

            if (data.statusId == '0'){
                navigate('/login')
                // window.location.replace(`https://sdp-s-vas-payment.telkom.co.za/${svc_id}?msisdn=${msisdn}&subscription_id=${subscription_id}&ext_ref=${ext_ref}`)
            }
        })
        .catch(e => {
            setLoading(false);
            navigate("/");
        });

    if (loading) return <h1>Redirecting....</h1>;
    return null;
};

export default SdpRedirect;
