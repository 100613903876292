import React, { useEffect } from 'react';
import logo from '../assets/logo.png';
import banner from '../assets/NdotoTriviaBanner.jpg'
import './Home.css'; // Ensure CSS file is imported
import play from '../assets/puzzle.jpeg'
import Cookies from 'js-cookie'
import {useFetcher, useNavigate} from 'react-router-dom'
const Home = () => {
const navigate=useNavigate()
    const msisdn=Cookies.get('msisdn')
console.log("cookie num", msisdn)

const checkuser=()=>{
  if(!msisdn || msisdn==null || msisdn==undefined){
    navigate('/login')
    }
}

useEffect(()=>{
    checkuser()
},[])

    const handleclick = () => {
        window.location.replace('https://ndototrivia-za.netlify.app/')
    }

    const handlePlay = () => {
        { window.location.replace(`https://www.gameninja.in/html/Gentech//hextrisMatch3/index.html?ani=${msisdn}&game_id=1`) }
    }
    return (
        <div>
            <nav className="bg-white border-gray-200 border dark:bg-gray-900">
                <div className="max-w-screen-xl px-[80px] flex flex-wrap items-center mx-auto p-4">
                    <a className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={logo} className='h-[95px] w-[200px]' alt="Winner Puzzle" />
                    </a>
                </div>
            </nav>

            <div className="bg-top   ">

                <div onClick={handleclick} className='flex  md:px-[110px] px-7 hover:cursor-pointer py-6 justify-center mx-auto'>
                    <img class="h-auto w-full " src={banner} alt="Banner" />
                </div>

                <div className='flex-col flex md:flex md:flex-row border px-7 py-2 justify-around'>

                    <div >
                        <img className="md:h-[330px] h-[200px] relative  " src={play} alt="Play games" />
                        <button onClick={handlePlay} type="button" className="hover:cursor-pointer text-white absolute mt-[-80px] mx-[80px] md:mt-[-130px] md:mx-[210px] bg-green-300 hover:bg-green-500 focus:ring-4 font-bold focus:ring-blue-300   text-sm px-5 py-2 md:py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                            PLAY NOW
                        </button>

                    </div>
                    <div className='h-[330px] mt-7 md:mt-0 md:w-[340px] bg-[#232930] '>
                        <div className='border border-b-1 border-[#FFCB1A]'>
                            <h1 className='text-[#FFCB1A] py-3 px-5 font-normal text-xl'>SCORE BOARD</h1>
                        </div>

                        <div class="flex flex-row h-[100px] gap-[220px] px-10 marquee">
                            <div> <h1 class="text-[#FFCB1A]">NAME</h1></div>
                           <div>
                            <h1 class="text-white">
                                12345
                            </h1>

                           </div>
                        </div>


                    </div>
                </div>


            </div>

            <div className="whitish-area">

            </div>


            <div className="bg-bottom ">

            </div>

        </div>
    );
}

export default Home;
